@media screen {
  body {
    background-color: white;
    font-family: verdana, Arial, Helvetica, sans-serif;
  }

  .border {
    background-color: #ABDAF0;
  }

  hr {
    display: block;
    height: 1px;
    border: 0;
    border-top: 1px solid #ABDAF0;
    width: 100%;
  }

  .header {
    font-family: verdana, Arial, Helvetica, sans-serif;
    font-weight: bold;
    color: white;
    background-color: #00A1D8;
  }

  .subheader {
    font-family: verdana, Arial, Helvetica, sans-serif;
    font-weight: bold;
    color: black;
    background-color: #ABDAF0;
  }

  .footer {
    font-family: verdana, Arial, Helvetica, sans-serif;
    font-size: 75%;
    text-align: right;
  }

  .footer.boeken {
    text-align: left;
    vertical-align: top;
    white-space: nowrap;
  }

  .footer.historiek-data {
    text-align: center;
    vertical-align: top;
    width: 100%;
  }

  .footer.datum {
    text-align: right;
    vertical-align: top;
    white-space: nowrap;
  }

  .cell, .nowrapcell {
    background-color: white;
    font-family: verdana, Arial, Helvetica, sans-serif;
  }

  .nowrapcell {
    white-space: nowrap;
  }

  .special1 {
    vertical-align: middle;
    font-weight: bold;
    border-top: 1px dashed black;
    border-right: 1px dashed black;
    background-color: #ABDAF0;
    font-family: verdana, Arial, Helvetica, sans-serif;
  }

  .special2 {
    border-top: 1px dashed black;
    background-color: #ABDAF0;
    font-family: verdana, Arial, Helvetica, sans-serif;
  }

  .special3 {
    border-top: 1px dashed black;
    border-left: 1px dashed black;
    background-color: #E6E6E6;
    font-family: verdana, Arial, Helvetica, sans-serif;
  }

  a:link, a:active, a:visited, a:hover {
    color: #00A1D8;
  }

  .header a:link, .header a:active, .header a:visited, .header a:hover {
    color: white;
  }

  pre {
    font-size: 100%;
    font-family: verdana, Arial, Helvetica, sans-serif;
    margin-top: 0;
    margin-bottom: 0;
  }

  .intramuros {
    background-color: #FFCCCC;
  }

  .extramuros {
    background-color: #CCCCFF;
  }

  .intralab {
    background-color: #CCFFCC;
  }

  .legend {
    font-weight: bold;
    padding: 3px;
    text-align: center;
  }

  .extra {
    font-family: Arial, Helvetica, sans-serif;
    font-size: 75%;
  }

  .extra-left-margin {
    font-family: Arial, Helvetica, sans-serif;
    font-size: 75%;
    margin-left: 10px;
  }

  p {
    margin-top: 3px;
    margin-bottom: 3px;
  }

  .custom table {
    width: 100%;
  }

  .custom table, .custom td, .custom th {
    border: 1px solid black;
    border-collapse: collapse;
  }

  .custom th {
    background-color: #F2F2F2;
  }

  .title {
    background-color: #E6E6E6;
  }

  .error {
    color: red;
    font-weight: bold;
  }

  .preserve-text, .noformat {
    white-space: pre-wrap;
  }

  .historiek {
    background: url("/outdated.png") !important;
  }

  .historiek .header {
    background-color: #999999;
  }

  .historiek .cell, .historiek .title, .historiek .border, .historiek .special1, .historiek .special2 {
    background-color: transparent;
    border-top: 1px solid black;
  }

  .historiek .title {
    font-weight: bold;
  }

  .linktarget {
    display: none;
  }

  .font-size-50 {
    font-size: 50%;
  }

  .font-size-75 {
    font-size: 75%;
  }

  .font-size-100 {
    font-size: 100%;
  }

  .font-size-150 {
    font-size: 150%;
  }

  .font-size-200 {
    font-size: 200%;
  }

  .font-size-400 {
    font-size: 400%;
  }
}
